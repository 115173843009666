<template>
  <q-page class="ub-page">
    <q-card style="padding: 5px;height: 100%">
      <div class="q-pa-md">
        <div class="col-6" style="display: flex">
          <input
            id="searchComp"
            :disabled="$store.state.progress"
            v-model="searchNum"
            style="width: 300px;text-align: center;margin: auto"
          />
        </div>
        <div class="q-pa-lg flex flex-center">
          <q-pagination
            v-model="pagination.page"
            :max="pagesNumber"
            direction-links
          />
          <select v-model="pagination.rowsPerPage">
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="100">100</option>
            <option value="250">250</option>
            <option value="500">250</option>
            <option value="1000">1000</option>
            <option value="1500">1500</option>
            <option value="2500">2500</option>
            <option value="5000">5000</option>
          </select>
        </div>
        <q-table
          hide-bottom
          title="Группы ТГ"
          :data="groups"
          :columns="columns"
          row-key="chat_name"
          separator="cell"
          dense
          :rows-per-page-options="[0]"
        >
          <template v-slot:body="props">
            <q-tr :props="props">
              <q-td :key="r.name" :props="props" v-for="r in columns">
                <q-btn
                  v-if="r.name == 'add-balance'"
                  style=""
                  label="Пополнить"
                  color="blue"
                  @click="setMoney(props.row)"
                />
                <q-btn
                  v-else-if="r.name == 'enable'"
                  :color="props.row[r.name] ? 'red' : 'green'"
                  :label="props.row[r.name] ? 'Выключить' : 'Включить'"
                  @click="setEnable(props.row)"
                />
                <q-btn
                  v-else-if="r.name == 'clear'"
                  color="red"
                  label="Очистить"
                  @click="showDel(props.row)"
                />
                <div v-else-if="r.name == 'chat_name'">
                  <b>{{ props.row[r.name] }}</b>
                </div>
                <div v-else-if="r.name == 'tarif'">
                  <b>{{ props.row[r.name] != 2 ? "15" : "18" }}</b>
                </div>
                <div v-else>{{ props.row[r.name] }}</div>
              </q-td>
            </q-tr>
          </template>
        </q-table>
      </div>
    </q-card>
    <q-dialog v-model="dsetmoney" persistent>
      <q-card style="min-width: 150px">
        <q-card-section>
          <div class="text-h6">Пополнить баланс {{ ggroup }}</div>
        </q-card-section>

        <q-card-section class="q-pt-none">
          <q-input dense v-model="gmoney" type="number" autofocus />
        </q-card-section>

        <q-card-actions align="right" class="text-primary">
          <q-btn
            label="Отмена"
            color="red"
            v-close-popup
            style="margin-right: 25px"
          />
          <q-btn label="Пополнить" @click="sendMoney()" color="blue" />
        </q-card-actions>
      </q-card>
    </q-dialog>
    <q-dialog v-model="delshow">
      <q-card>
        <q-card-section>
          <div class="text-h6 text-center">Подтвердите очистку группы <br/><b>{{delgroup}}</b></div>
        </q-card-section>
        <q-card-actions align="center">
          <q-btn align="left" label="Очистить" color="red" @click="clearGroup(delgroupid)" />
          <q-btn  align="right" label="Передумал" color="green" v-close-popup />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </q-page>
</template>

<script>
import { Notify } from "quasar";

export default {
  name: "billing_groups",
  components: {},
  data() {
    return {
      dsetmoney: false,
      delshow:false,
      delgroup:"",
      delgroupid:0,
      ggroup: "",
      gmoney: 0.0,
      ggroupid: 0,
      groups: [],
      columns: [],
      searchNum: "",
      pagination: {
        sortBy: "desc",
        descending: false,
        page: 0,
        rowsPerPage: 20
        // rowsNumber: xx if getting data from a server
      },
      max: 0,
      selected: []
    };
  },
  computed: {
    pagesNumber() {
      return Math.ceil(this.max / this.pagination.rowsPerPage);
    }
  },
  created() {
    if (this.$route.query.limit) {
      this.pagination.rowsPerPage = parseInt(this.$route.query.limit);
    }

    if (this.$route.query.search) {
      this.searchNum = this.$route.query.search;
    }
    if (this.$route.query.current) {
      this.pagination.page = parseInt(this.$route.query.current) || 1;
    }
  },
  mounted() {
    this.getInfo();
  },
  watch: {
    searchNum(val) {
      this.$router.push({
        path: this.$route.path,
        query: {
          current: this.pagination.page,
          search: val,
          limit: this.pagination.rowsPerPage
        }
      });
      if (val.toString().length > 2 || val.toString().length == 0) {
        this.getInfo();
      }
    },

    "pagination.page"(val) {
      this.$router.push({
        path: this.$route.path,
        query: {
          current: val || 1,
          search: this.searchNum,
          limit: this.pagination.rowsPerPage
        }
      });

      this.getInfo();
    },
    "pagination.rowsPerPage"(val) {
      this.$router.push({
        path: this.$route.path,
        query: {
          current: this.pagination.page,
          search: this.searchNum,
          limit: val
        }
      });
      this.getInfo();
    }
  },
  methods: {
    setMoney(g) {
      this.ggroup = g.chat_name;
      this.ggroupid = g.chat_id;
      this.dsetmoney = true;
    },
    showDel(g) {
      this.delgroup = g.chat_name;
      this.delgroupid = g.chat_id;
      this.delshow = true;
    },
    sendMoney() {
      this.$store.state.progress = true;
      this.$h
        .post("/billing/set-money", { g: this.ggroupid, m: this.gmoney })
        .then(r => {
          if (r.data.error) {
            Notify.create({
              position: "top",
              type: "errors",
              color: "red",
              message: "Ошибка",
              timeout: 2000
            });
          } else {
            Notify.create({
              position: "top",
              color: "green",
              message: r.data.message,
              timeout: 2000
            });
          }
          this.dsetmoney = false;
          this.$store.state.progress = false;
        });
    },

    setEnable(g) {
      this.$store.state.progress = true;
      g.enable = g.enable == 1 ? 0 : 1;
      this.$h
        .post("/billing/set-enable", { g: g.chat_id, e: g.enable })
        .then(r => {
          if (r.data.error) {
            Notify.create({
              position: "top",
              type: "errors",
              color: "red",
              message: "Ошибка",
              timeout: 2000
            });
          } else {
            Notify.create({
              position: "top",
              color: "green",
              message: r.data.message,
              timeout: 2000
            });
          }
          this.dsetmoney = false;
          this.$store.state.progress = false;
        });
    },
    clearGroup(g) {
      this.$store.state.progress = true;
      this.$h
        .post("/billing/clear-group", { g: g})
        .then(r => {
          if (r.data.error) {
            Notify.create({
              position: "top",
              type: "errors",
              color: "red",
              message: "Ошибка",
              timeout: 2000
            });
          } else {
            Notify.create({
              position: "top",
              color: "green",
              message: r.data.message,
              timeout: 2000
            });
          }
          this.delshow = false;
          this.$store.state.progress = false;
        });
    },
    getInfo() {
      this.$store.state.progress = true;
      this.$h
        .get(
          `/billing/get-group-balance?s=${this.searchNum}&l=${
            this.pagination.rowsPerPage
          }&o=${this.pagination.page - 1}`
        )
        .then(r => {
          this.columns = r.data.data.c;
          this.groups = r.data.data.g;
          this.max = r.data.data.m;
          this.$store.state.progress = false;
        });
    }
  }
};
</script>

<style></style>
